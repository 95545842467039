export const CAPTURE_EVENTS = [
  'focusout',
  'blur',
  'focusin',
  'focus',
  'load',
  'unload',
  'mouseenter',
  'mouseleave'
]
